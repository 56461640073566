.Icon-button {
  display: inline-flex;

  fill: var(--color-brand-neutral);
  transition: fill 200ms ease;
  &:hover {
    fill: var(--color-brand-primary);
  }
  &:focus {
    fill: var(--color-brand-primary);
  }
}

.icon-large {
  /*width: 2.5rem;
  height: 2.5rem;*/
  transform: scale(2.5);
}
.Icon-large-left {
  transform-origin: center;
  transform: scale(1.0);
  position: absolute;
  left: 10%;
  top: 50%;
}
.Icon-large-right {
  transform-origin: center;
  transform: scale(1.0);
  position: absolute;
  right: 10%;
  top: 50%;
}

.rotate-right {
  transform-origin: center;
  transform: rotateY(0deg);
}

.rotate-left {
  transform-origin: center;
  transform: rotateY(180deg);

  //left: 6rem;
  //top: 50%;

}

.Icon-checkmark {
  /*width: 100%;
  height: 100%;
  transform: scale(1.25);
  fill: white;*/
}
.Icon-chevron {
  width: 100%;
  height: 100%;
  display: block;
  //transform: scale(1.25);
  //fill: teal;
  fill: var(--color-ui-border-heavy);
  transition: transform 100ms ease;
}
.Icon-container.Chevron {
  display: inline-flex;
  height: 1rem;
  width: 1rem;
  position: absolute;
  right: 0.75rem;
}
.Icon-container.Chevron.expanded > svg {
  transform-origin: 50% 50%;
  transform: rotateX(180deg);
  /*
  transform-origin: 50% 40%;
  transform: matrix(-1,0,0,1,1,4) rotate(180deg);
    */
  //transform: rotate3d(1,0,0, 180deg);
  //transform: rotate(180deg);
}

.Icon-magnifier {
  fill: inherit;
}
.icon-size-16 {
  height: 1rem;
  width: 1rem;
}

.Icon-logo {

}
.Cerner-logo {
  width: 2rem;
  height: 2rem;
  & > path:not(:first-child) {
    fill: white;
  }
}
