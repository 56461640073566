.Button {

  text-decoration: none;

  --bg: #000000;
  --fg: #ffffff;
  background-color: var(--bg);
  color: var(--fg);
  &:hover {
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  }
  &.small {
    padding: 0.25rem 0.75rem;
    height: var(--size-control-height-small);
  }
  &.medium {
    padding: 0.5rem 0.75rem;
    height: var(--size-control-height-medium);
  }
  &.large {
    padding: 0.75rem 1.5rem;
    height: var(--size-control-height-large);
  }
}
.Button.primary {
  --bg: var(--color-brand-primary);
  --bg-hover: var(--color-brand-primary-hover);
  //background-color: var(--color-brand-primary);
  --fg: var(--gray_100);
  --fg-hover: var(--white);
  /*&:hover {
    //background-color: var(--color-brand-accent-hover);
    //border-color: var(--color-brand-accent-hover);
    --bg: var(--color-brand-accent-hover);
  }*/
}
.Button.accent {
  --bg: var(--color-brand-accent);
  --bg-hover: var(--color-brand-accent-hover);
  //background-color: var(--color-brand-primary);
  --fg: var(--gray_900);
  --fg-hover: var(--white);
}

.Button.neutral {
  --bg: var(--coolGray80);
  --bg-hover: var(--coolGray90);
  //background-color: var(--color-brand-primary);
  --fg: var(--white);
  --fg-hover: var(--white);
}

.Button.system {
  --bg: var(--coolGray20);
  --bg-hover: var(--coolGray40);
  //background-color: var(--color-brand-primary);
  --fg: var(--grey_950);
  --fg-hover: var(--grey_950);
}

.Button.fill {
  background-color: var(--bg);
  color: var(--fg);
  border: 2px solid var(--bg);
  &:hover {
    background-color: var(--bg-hover);
    border-color: var(--bg-hover);
  }
}

.Button.outline {
  background-color: transparent;
  color: var(--bg);
  border: 2px solid var(--bg);
  &:hover {
    //$temp-bg: #{var(--bg)};
    //@debug "value is #{$temp-bg}";
    //background-color: change-color($temp-bg, $alpha: 0.6);
    background-color: var(--bg);
    border-color: var(--bg);
    color: var(--fg-hover);
  }
  &:active {
    --bg: var(--grey_950);
  }
}
