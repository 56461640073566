.App-header {
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  border-bottom: 2px solid rgb(38 128 235 / 20%);
  background-color: white;
  box-shadow: rgb(38 128 235 / 10%) 0px 4px 7px 0px;

  @media screen and (max-width: 40em) {
    padding: 0 1rem;
  }
}
.App-header > .name-logo {
  font-size: 2rem;
  //font-weight: 800;
  //font-family: 'Inter', sans-serif;
  //letter-spacing: -0.06em;
  font-family: GilroyLight, sans-serif;
  letter-spacing: -0.02em;
  opacity: 0.8;
  @media screen and (max-width: 40em) {
    font-size: 1.5rem;
  }
}
.App-header > .Social-icons {
  margin-top: 0;
}
