@use "sass:list";
@use "src/styles/theme/typography" as typography;
.ProjectCardFull-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
  background-color: white;
  display: block;
}
.ProjectCardFull-container.hide {
  animation: Hide 200ms 200ms forwards;
}
.ProjectCardFull-container.show {
  animation: Show 200ms forwards;
}
@keyframes Hide {
  0% {
    opacity: 1;
    display: block;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
    transform: scale(0);
  }
}
@keyframes Show {
  0% {
    opacity: 0;
    display: none;
    z-index: -1;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    display: block;
    transform: scale(1);
  }
}
@keyframes Expand {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    //opacity: 0;
    transform: scale(1.5);
  }
}
.ProjectCard-container {
  max-width: 24rem;
  border: 1px solid;
  transform: scale(1);
  transition: transform 200ms ease;
  &.expanded {
    transform: scale(1.5);
    //animation: Expand 200ms forwards;
  }
  /*&.expanded {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20000;
  }*/
  background-color: white;
}
.ProjectCard-content {
  padding: 1rem;
}
.ProjectCard-content > a {
  text-decoration: none;
  & > h3 {
    color: #212121;
    margin-bottom: 0.5rem;
  }
}
.ProjectCard-body {
}
.ProjectCard-tags {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}
.ProjectCard-tag {
  border: 1px solid;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  &:not(:first-child) {
    margin-left: 0.25rem;
  }
}

.Featured-project-lightbox {
  max-width: 100vw;
  //display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 4rem;
  padding: 2rem;
  background-color: #ffffff;
  //width: 80%;
  //margin: 0 auto;
  position: relative;
  @media screen and (max-width: 40em){
    padding: 0;//1rem;
    //maybe remove thid next line
    width: 100%;
    padding: 4rem 1rem;
  }
}
.Featured-project-lightbox > .Close-button-container {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: rgba(0,0,0,0);
  @media screen and (max-width: 40em){
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
  }
}
.Close-button-container > .Close-button {
  background-color: inherit;
 // width: ;
}
.Close-button > svg {
  width: 100%;
  height: 100%;
}
.Modal {
  //padding: 2rem;
  //margin: 4rem;
  max-width: 64vw;
  @media screen and (max-width: 40em){
    padding: 0rem;
    max-width: revert;
  }
  //height: 100vh;
}
.ProjectTimelineItem-header {
  margin-left: 1rem;
}
.Featured-project-lightbox > .details > header > .Type-heading {
  @media screen and (max-width: 40em){
    font-size: list.nth(typography.$type-scale, 5);
  }
}

.Featured-project-preview {
  background-color: var(--coolGray10);
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  //align-self: center;
  align-items: center;
  margin-inline: auto;
  box-shadow: 1px 2px 4px 1px rgb(25 25 25 / 20%), -1px -1px 2px 0px rgb(25 25 25 / 20%);
  &>h2{
    align-self: center;
  }
}
.Featured-project-preview-logos {
  padding: 2rem;
  @media screen and (max-width: 40em){
    padding: 2rem 1rem;
  }
  //padding: 1rem;
  //padding-top: 0;
  & > svg {
    width: 2rem;
    height: 2rem;
    @media screen and (max-width: 40em){
      width: 100%;
      height: 100%;
      max-width: 1rem;
    }
  }
  & > svg:not(:first-child) {
    margin-left: 1rem;
  }
}

.Featured-project-card {
  background-color: var(--white);
  padding: 1rem;
  max-width: 60rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  @media screen and (max-width: 40em){
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  &.lightbox {
    width: 100%;

  }
}
.Mobile-header {
  @media screen and (min-width: 40em){
    display: none;
  }
}

.Featured-project-card > .details {
  @media screen and (max-width: 40em){
    display: none;
  }
}

.Featured-project-card > .details > header {
  margin-bottom: 1rem;
}
.Featured-project-card > .mobile {
  display: none;
  @media screen and (max-width: 40em){
    display: inline-block;
  }
}
.gallery {
  position: relative;
}
.Tech-logos {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

}
.gallery > .Tech-logos {
  @media screen and (max-width: 40em){
    display: none;
  }
}
.Tech-logos.mobile {
  order: 2;
  display: none;
  margin-top: 1rem;
  @media screen and (max-width: 40em){
    display: inline-block;
  }
}
.Tech-logo {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
  margin-right: 1rem;

  & > svg {
    height: 100%;
    width: 100%;
  }
}
.Tech-logos.mobile > .Tech-logo {
  height: 1.25rem;
  width: 1.25rem;
  opacity: 0.8;
}
.split {

}
