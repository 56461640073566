.RotatingText-Wrapper {
  position: relative;
  overflow: hidden;
  width: 800px;
  height: 100px;
}
.RotatingText-Word {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 100px;
  line-height: 100px;
  text-align: center;

  transform: translate3d(0, 100px, 0);
  animation: Cycle 10s infinite;
  transition-timing-function: cubic-bezier(0.2,0.5,0.3,1);
}

.RotatingText-Word:nth-of-type(2) {
  animation-delay: 2.5s;
}
.RotatingText-Word:nth-of-type(3) {
  animation-delay: 5s;
}
.RotatingText-Word:nth-of-type(4) {
  animation-delay: 7.5s;
}


/*.RotatingText-Word:nth-of-type(1) {
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
}
.RotatingText-Word:nth-of-type(2) {
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-delay: 1.75s;
}
.RotatingText-Word:nth-of-type(3) {
  animation-name: rotate-last;
  animation-duration: 1.5s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}*/


@keyframes Cycle {
  0% {
    transform: translate3d(0, 100px, 0);
  }
  2.5% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(0, 0, 0);
  }
  27.5% {
    transform: translate3d(0, -100px, 0);
  }
  99.9999% {
    transform: translate3d(0, -100px, 0);
  }
  100% {
    transform: translate3d(0, 100px, 0);
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  20%, 80% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
}

@keyframes rotate-last {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  50%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}