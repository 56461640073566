$breakpoint: 40em;
.App {
  min-height: 100vh;
}
.Layout_Timeline-example {
  max-width: 100vw;
  overflow: hidden;
}
.WorkTimelineItem-logos {
  padding: 1rem;
  padding-top: 0;
  & > svg {
    width: 2rem;
    height: 2rem;
  }
  & > svg:not(:first-child) {
    margin-left: 1rem;
  }
}
.WorkTimelineItem-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid var(--black-10a);
  //padding: 0rem 0 0.5rem 0;
  padding: 1rem 1rem 1.5rem 1rem;
  margin-bottom: 0.5rem;


  /*box-shadow: rgb(255 153 2 / 20%) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;
  border-radius: 0 0 4rem 0.5rem;*/

  //box-shadow: rgb(255 153 2 / 20%) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;
  border-radius: 0 0 1rem 1rem;
  //c=rgb(4 125 188 / 10%) a=rgb(255 153 0 / 10%)
  background-color: rgba(var(--rgb), 0.1);
  box-shadow: rgba(var(--rgb), 0.1) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;
}
.WorkTimelineItem-header#amazon {
  --rgb: 255, 153, 0;
  /*background-color: rgb(255 153 0 / 10%);
  box-shadow: rgb(255 153 0 / 10%) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;*/
}
.WorkTimelineItem-header#cerner {
  --rgb: 4, 125, 188;
  /*background-color: rgb(4 125 188 / 10%);
  box-shadow: rgb(4 125 188 / 10%) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;*/
}
.WorkTimelineItem-header#huron {
  --rgb: 2, 84, 140;
  /*background-color: rgb(var(--rgb) / 10%);
  box-shadow: rgb(var(--rgb) / 10%) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;*/
}
.WorkTimelineItem-header#engage {
  --rgb: 244, 122, 35;
  /*background-color: rgb(var(--rgb) / 10%);
  box-shadow: rgb(var(--rgb) / 10%) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;*/
}
.WorkTimelineItem-header#drive_social_media {
  --rgb: 9, 52, 56;
  /*background-color: rgb(var(--rgb) / 10%);
  box-shadow: rgb(var(--rgb) / 10%) 0px 1px 5px, rgb(0 0 0 / 15%) 0px 1px 5px;*/
}
.Fade-effect {
  width: 200%;
  left: -4rem;
  height: 100%;
  display: inline-block;
  z-index: 99999;
  position: absolute;
  background-color: #ffffff;
  transform-origin: bottom;
  //transform: scaleY(1.5);
  //opacity: 1;
  //bottom: 4rem;
  filter: blur(2rem);
}
.animate > * > * > * > .Fade-effect {
  animation: FadeFx 1200ms ease forwards;
}
@keyframes FadeFx {
  0% {
    transform: scaleY(1.5);
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    opacity: 0;
  }
}
.WorkTimelineItem-list {
  padding: 1rem;
  list-style: disc;
  margin-left: 1rem;
  @media screen and (max-width: 40em){
    padding-bottom: 0;
  }
}
.WorkTimelineItem-list-item {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  //border-bottom: 1px solid var(--cool-grey-40a);
  //border-bottom: 1px solid var(--cool-grey-10a);
}
.buffer {
  min-height: 100vh;
  width: 100%;
  background-color: var(--color-ui-background-l);
}
.Timeline {
  position: relative;
  background-color: var(--gray_300);
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 1rem 0;
  padding-top: 4rem;
  &::before {
    content: '';
    position: absolute;
    width: 0.25rem;
    //set left to half container when displaying flex
    left: 50%;
    top: 0;
    height: 100%;
    background-color: #ffffff;
  }
}
//
.Timeline.one-column::before {
  content: '';
  position: absolute;
  width: 0.25rem;
  //set left to half container when displaying flex
  left: 2rem;
  top: 0;
  height: 100%;
  background-color: #ffffff;
}
.Timeline::before {
  @media screen and (max-width: $breakpoint){
    content: '';
    position: absolute;
    width: 0.25rem;
    //set left to half container when displaying flex
    left: 2rem;
    top: 0;
    height: 100%;
    background-color: #ffffff;
  }
}
//
.Timeline_marker {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  border-radius: 50%;
  //background-color: white;
  background-color: #17eaec;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 50%; */
  overflow: hidden;

  //left = 50% - (this.width/2) + (timeline.width/2)
  left: calc(50% - 1.25rem + 2px);
  box-shadow:  #eaeaeaaa 0 0 0 2px, #fff 0 0 0 4px;
  top: 1.5rem;
  &.one-column {
    left: calc(2rem - 1.25rem + 2px);
  }
  @media screen and (max-width: $breakpoint){
    left: calc(2rem - 1.25rem + 2px);
  }

  transform: scale(0);
}

.animate > * > .Timeline_marker {
  animation: Grow 200ms ease forwards;
}

.Timeline_marker > svg {
  fill: var(--white) !important;
}

.Timeline_marker > svg > g {
  fill: var(--white) !important;
}
.Timeline_marker > svg#svg-react {
  width: 75%;
  height: 75%;
  & > g {
    fill: none !important;
  }
  & > g > g {
    stroke: white;
  }
  & > g > circle {
    fill: white;
  }
}
.Timeline_marker > svg#svg-nest {
  width: 75%;
  height: 75%;
  & > path {
    fill: white;
  }
}
.Timeline-entry {
  display: flex;

  position: relative;

  &.one-column {
    padding: 0 0 0 4rem;
  }
  @media screen and (max-width: $breakpoint){
    padding: 0 0 0 2.5rem;
  }
}
.Timeline-item {
//rgb(52 58 63 / 10%) -14px -10px 4px 0
}
/*.Timeline-item.Card::before {
  content: '';
  position: absolute;
  top: 12%;
  left: 44%;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--color-ui-border-lightest);
  transform: rotateZ(45deg);
  border-left: none;
  border-bottom: none;
  background-color: var(--white);
  !* border-radius: 0.25rem; *!
  overflow: hidden;
  z-index: 0;
}*/
.Timeline-item_arrow {
  position: absolute;
  /*top: 12%;
  left: 44%;
  width: 1rem;
  height: 1rem;*/
  top: 10%;
  left: 40%;
  width: 3rem;
  height: 3rem;
  border: 1px solid var(--color-ui-border-lightest);
  transform: rotateZ(45deg);
  border-left: none;
  border-bottom: none;
  background-color: var(--color-ui-background-l);
  overflow: hidden;
  z-index: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%);
}
.Timeline-item_arrow.reverse {
  position: absolute;
  /*top: 12%;
  left: 44%;
  width: 1rem;
  height: 1rem;*/
  top: 10%;
  left: 60%;
  width: 3rem;
  height: 3rem;
  border: 1px solid var(--color-ui-border-lightest);
  transform: rotateZ(225deg);
  border-left: none;
  border-bottom: none;
  background-color: var(--color-ui-background-l);
  overflow: hidden;
  z-index: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%);
}
.Timeline-item.Card {
  max-width: 45%;
  text-align: left;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  //border: 1px solid var(--color-ui-border-lightest);
  //background-color: var(--color-ui-background-l);
  //overflow: hidden;
  z-index: 1;
  border-radius: 0.25rem;

  &.one-column {
    max-width: 100%;
  }
  @media screen and (max-width: $breakpoint){
    max-width: 100%;
  }
}
/*.Timeline-item.Card[data-visible=true] {
  & > .Timeline-item_content {
    animation: Bounce-in 500ms ease forwards;
    animation-delay: 500ms;
  }
}*/
.IntersectionObserver-target {}
.animate {
  /*& > * > * > .Timeline-item_content.reverse {
    animation: Bounce-in 500ms cubic-bezier(0, 0, 0.2, 1) forwards;//, Shadow-in 500ms cubic-bezier(0,0,0.2,1) forwards;
  }*/

  & > * > * > .Timeline-item_content.reverse {
    animation: SlideIn-right 400ms ease forwards;
  }
  & > * > * > .Timeline-item_date.reverse > * {
    animation: SlideIn-right-text 400ms 250ms ease forwards;
  }


  & > * > * > .Timeline-item_content {
    animation: SlideIn-left 400ms ease forwards;
  }
  & > * > * > .Timeline-item_date > * {
    animation: SlideIn-left-text 400ms 250ms ease forwards;
  }
}
.no-animate {

  & > * > * > .Timeline-item_content.reverse {
    animation: Bounce-in 500ms cubic-bezier(0, 0, 0.2, 1) both;//, Shadow-in 500ms cubic-bezier(0,0,0.2,1) forwards;
  }
  & > * > * > .Timeline-item_content {
    animation: Bounce-in 500ms cubic-bezier(0, 0, 0.2, 1) both;//, Shadow-in-mirrored 500ms cubic-bezier(0,0,0.2,1) forwards;
  }
}
/*.animate {
  animation: Bounce-in 500ms forwards;
}*/
.Timeline-item_content {
  opacity: 0;
  //animation: Bounce-in reverse;
  //animation-play-state: paused;

  //box-shadow: rgb(52 58 63 / 10%) -14px -10px 4px 0;
  border: 1px solid var(--color-ui-border-lightest);
  border-top: 0.25rem #17eaec solid;
  background-color: var(--color-ui-background-l);
  //padding: 1rem;
  border-right: 0.25rem;
  & > :first-child {
    margin-bottom: 0.5rem;
  }
  &.Amazon {
    border-top: 0.25rem #ff9900 solid;
  }
}
.Timeline-item_content.project-timeline {
  padding: 1rem;
}
.Timeline-item_date {
  position: absolute;
  left: 55%;
  right: 0;
  /*@media screen and (max-width: $breakpoint){
    position: relative;
    margin-top: -2rem;
    margin-bottom: 2rem;
  }*/
}
.Timeline-item_date.OneColumn {
  display: none;
  @media screen and (max-width: $breakpoint){
    display: inline-block;
    position: relative;
    left: 0;
    padding: 1rem 0 0 0rem;
    &.Timeline_work {
      //padding: 1rem 0 0 1rem;
      padding: 1rem 1rem 2rem 1rem;
      display: flex;
      //justify-content: center;
    }
    &.Timeline_project {
      /*@media screen and (max-width: 40em){
        padding: 0rem 0 0 1rem;
      }*/
      padding: 1rem 1rem 2rem 1rem;
      display: flex;
      //justify-content: center;
    }
  }
  & > * {
    color: var(--color-brand-neutral);
    font-weight: 500;
  }
}
.Timeline-item_date.TwoColumn {
  top: 2rem;

  overflow: hidden;
  & > * > * {
    color: var(--color-brand-neutral);
    font-weight: 700;
  }
  @media screen and (max-width: $breakpoint){
    display: none;
  }
}
.Timeline-entry.reverse {
  flex-direction: row-reverse;
}
.Timeline-item_date.reverse {
  left: 0;
  right: 55%;
  text-align: right;
}

@keyframes Grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes Slide-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  50% {
    opacity: 1;
    transform: translateX(-10%);
  }
  80% {
    opacity: 1;
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/**
  Animations
 */

@keyframes SlideIn-left {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  50% {
    opacity: 0;
    transform: translateX(-50%);
  }
  90% {
    opacity: 1;
    transform: translateX(4%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes SlideIn-left-text {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  50% {
    opacity: 0;
    transform: translateX(-20%);
  }
  90% {
    opacity: 1;
    transform: translateX(4%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes SlideIn-right {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }
  50% {
    opacity: 0;
    transform: translateX(50%);
  }
  90% {
    opacity: 1;
    transform: translateX(-4%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes SlideIn-right-text {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }
  50% {
    opacity: 0;
    transform: translateX(20%);
  }
  90% {
    opacity: 1;
    transform: translateX(-4%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes Bounce-in {
  0% {
    transform-origin: center;
    opacity: 0;
    transform: scale(0.5);
    //box-shadow: rgba(25,25,42,1) 100px 2px 4px 0, rgba(25,25,42,1) 100px -100px 8px 8px;
    //box-shadow: rgba(25,25,42,0.25) -100px 200px 40px 0;//, rgba(25,25,42,1) 100px -100px 8px 8px;
  }
  /*40% {
    opacity: 1;
    transform: scale(0.8);
  }*/
  /*20% {
    background-color: rgba(206, 153, 255, 0.5);
    color: white;
    opacity: 1;
  }*/
  70% {
    //background-color: rgba(153, 189, 255, 0.2);
    //color: var(--grey_800);
    //opacity: 1;
    //box-shadow: rgba(25,25,42,0.2) -50px 100px 40px 0;
    transform: scale(1.1);
  }
  /*80% {
    transform: scale(1.025);
  }*/
  100% {
    background-color: #ffffff;
    //color: black;
    //box-shadow: rgba(25,25,42,0.1) 1px 2px 8px 2px;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes Shadow-in {
  0% {
    //box-shadow: rgba(25,25,42,1) 100px 2px 4px 0, rgba(25,25,42,1) 100px -100px 8px 8px;
    box-shadow: rgba(25,25,42,0.25) -100px 200px 40px 0;//, rgba(25,25,42,1) 100px -100px 8px 8px;
  }
  70% {
    box-shadow: rgba(25,25,42,0.2) -50px 100px 40px 0;
  }
  100% {
    box-shadow: rgba(25,25,42,0.1) 1px 2px 8px 2px;
  }
}
@keyframes Shadow-in-mirrored {
  0% {
    //box-shadow: rgba(25,25,42,1) 100px 2px 4px 0, rgba(25,25,42,1) 100px -100px 8px 8px;
    box-shadow: rgba(25,25,42,0.25) 100px 200px 40px 0;//, rgba(25,25,42,1) 100px -100px 8px 8px;
  }
  70% {
    box-shadow: rgba(25,25,42,0.2) 50px 100px 40px 0;
  }
  100% {
    box-shadow: rgba(25,25,42,0.1) 1px 2px 8px 2px;
  }
}
@keyframes Fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Fade-container {
  opacity: 0;
}
