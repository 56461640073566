body {
  color: var(--gray_900);
  background-color: var(--gray_200);
  //background-color: var(--white);
  font-family: 'Helvetica Neue', sans-serif;
}

:focus-visible {
  outline: 2px solid var(--color-brand-neutral);
}

.App {
  max-width: 100vw;
  overflow-x: hidden;
}