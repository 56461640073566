@use "sass:map";
@use "sass:list";

$type-scale: (
        0.75rem,  //Small Label for form field labels or small metadata
        0.875rem, //medium label for buttons
        1rem,     //Large [label] for nav items and select items ALSO [body text] (default) ALSO [subtitle]
        1.125rem, //Large body text
        1.25rem,  //Title components.                                                  [subtitle]
        1.5rem,   //[MEDIUM]                                                           [subtitle]
        1.75rem,  //[LARGE]
        2rem,     //Heading components.
        2.25rem,  //[MEDIUM]
        2.5rem,   //[LARGE]
        3rem,     //Display/special super emphasis components. Not commonly used [SMALL]
        3.5rem,   //[MEDIUM]
        4rem      //[LARGE]
);

/*:root {
  //font-family: 'Frutiger';
  --ff-univers-1: 'Univers', sans-serif;
  --ff-univers-2: 'Univers-Black', sans-serif;
  --ff-univers-3: 'Univers-Light', sans-serif;
  --ff-univers-4: 'Univers-Light-Normal', sans-serif;
  --ff-univers-5: 'Univers LT Std', sans-serif;
  --ff-gilroy-1: 'Gilroy', sans-serif;
  --ff-gilroy-2: 'Gilroy-Black', sans-serif;
  --ff-gilroy-3: 'Gilroy-Bold', sans-serif;
  --ff-gilroy-4: 'Gilroy-ExtraBold', sans-serif;
  --ff-gilroy-5: 'Gilroy-Heavy', sans-serif;
  --ff-gilroy-6: 'Gilroy-SemiBold', sans-serif;
  --ff-gilroy-7: 'Gilroy-Regular', sans-serif;
  --ff-gilroy-8: 'Gilroy-Medium', sans-serif;
  --ff-gilroy-9: 'Gilroy-Medium', sans-serif;
  --ff-gilroy-10: 'Gilroy-Light', sans-serif;
  --ff-gilroy-11: 'Gilroy-Thin', sans-serif;
  --ff-gilroy-12: 'Gilroy-UltraLight', sans-serif;
  //--ff-1: 'Univers', 'Univers-Black', 'Univers-Light', 'Univers-Light-Normal', 'Univers LT Std', sans-serif;
  //--ff-2: 'Gilroy', 'Gilroy-Black', 'Gilroy-Bold','Gilroy-ExtraBold','Gilroy-Heavy','Gilroy-SemiBold','Gilroy-Regular','Gilroy-Medium','Gilroy-Light','Gilroy-Thin','Gilroy-UltraLight', sans-serif;
  --ff-0: 'Neue Haas Grotesk Display Pro', sans-serif;
  --ff-1: 'DM Sans', sans-serif;
  --ff-2: 'Noto Sans', sans-serif;
  --ff-3: 'Open Sans', sans-serif;
  --ff-4: 'Work Sans', sans-serif;
  --ff-5: 'Poppins', sans-serif;
  --ff-6: 'Kanit', sans-serif;
  --ff-7: 'Frutiger', sans-serif;
  --ff-8: 'Raleway', sans-serif;
  --ff-9: 'Mulish', sans-serif;
  --ff-10: 'IBM Plex Sans', sans-serif;
}*/

.Type-label {
  font-family: 'Inter', sans-serif;
}

.Type-label_button {
  /*font-size: 0.875rem;
  font-weight: 600;*/
  font-size: 1rem;
  font-weight: 600;
}
.Type-label.Label-level-1 {
  color: var(--color-text-muted);
  font-size: 0.875rem;
}
.Type-label.small {
  font-size: list.nth($type-scale, 1);
  font-weight: 600;
}
.Type-label.medium {
  font-size: list.nth($type-scale, 2);
  font-weight: 600;
}
.Type-label.large {
  font-size: list.nth($type-scale, 3);
  font-weight: 600;
}
.List-item-label {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}
.List-item-label.large {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.Type-body {
  font-size: list.nth($type-scale, 3);
  font-weight: 500;
  line-height: 1.5;
}
.Type-body.small {
  font-size: list.nth($type-scale, 2);
  //font-weight: 500;
  font-weight: 400;
}
.Type-body.large {
  font-size: list.nth($type-scale, 4);
  font-weight: 600;
}

.Type-subtitle.small {
  font-size: list.nth($type-scale, 3);
  font-weight: 600;
}
.Type-subtitle.medium {
  font-size: list.nth($type-scale, 5);
  font-weight: 600;
}
.Type-subtitle.large {
  font-size: list.nth($type-scale, 6);
  font-weight: 600;
}

.Type-title {
  //font-family: 'Inter', sans-serif;
  //font-family: 'Noto Sans', sans-serif;
  //--ff-0: 'Gilroy-Bold', sans-serif;
  font-family: GilroyBold, Inter, sans-serif;
  line-height: 1.2;
}
.Type-title.small {
  font-size: list.nth($type-scale, 5);
  //font-weight: 600;
  font-weight: 500;
}
.Type-title.medium {
  font-size: list.nth($type-scale, 6);
  font-weight: 600;
}
.Type-title.large {
  font-size: list.nth($type-scale, 7);
  font-weight: 600;
}

.Type-heading {
  //font-family: 'Gilroy', sans-serif;
  font-family: 'HelveticaNeueBold', sans-serif;
}

.Type-heading.small {
  font-size: list.nth($type-scale, 8);
  font-weight: 600;
}
.Type-heading.medium {
  font-size: list.nth($type-scale, 9);
  font-weight: 600;
}
.Type-heading.large {
  font-size: list.nth($type-scale, 10);
  font-weight: 600;
}

/*.Type-display {
  font-family: 'Gilroy-UltraLight', sans-serif;
}*/

.Type-display.small {
  font-size: list.nth($type-scale, 11);
  font-weight: 600;
}
.Type-display.medium {
  font-size: list.nth($type-scale, 12);
  font-weight: 600;
}
.Type-display.large {
  font-size: list.nth($type-scale, 13);
  font-weight: 600;
}
