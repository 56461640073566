.Avatar-container {
  --size: 2rem;
  height: var(--size);
  width: var(--size);
  background-color: #61dafb;
  clip-path: circle(50% at 50% 50%);
}
.Avatar-image {

}

.Avatar-effect {

}
.Big-avatar-container {
  display: inline-flex;
  margin-top: -2rem;
  margin-left: -2rem;
  position: relative;
  @media screen and (max-width: 640px){
    margin-top: 0;
    margin-left: 0;
    height: 60%;
    width: 60%;
  }
  @media screen and (max-width: 320px){
    margin-top: 0;
    margin-left: 0;
    height: 100%;
    width: 100%;
  }
}
.Avatar-effect.effect-1 {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--teal_200);
  top: -0.5rem;
  left: -0.5rem;
}
.Avatar-effect.effect-2 {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 8px solid rgba(117, 117, 241, 0.6);
  //border: 8px solid rgba(38, 128, 235, 0.6);

  top: 0;
  left: 0;
}
