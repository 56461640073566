* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  background-color: var(--color-ui-background-l);
  border: 2px solid transparent;
  border-radius: 0.125rem;
}

a, button {
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6,p {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

ul {
  list-style: none;
}

[type="search"] {
  /* clears the ‘X’ from Internet Explorer */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
    display: none;
  }
}
