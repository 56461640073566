$breakpoint: 40em;

:root {

  //Brand colors
  --color-brand-primary: var(--blue_500);
  --color-brand-primary-active: var(--blue_600);
  --color-brand-primary-hover: var(--blue_700);
  --color-brand-primary-selected: var(--blue_500);

  //#5ac9fa
  //#99e7ffcc

  --color-brand-secondary: #74C0D3FF;
  --color-brand-secondary-active: var(--blue_600);
  --color-brand-secondary-hover: var(--blue_700);
  --color-brand-secondary-selected: var(--blue_500);

  --color-brand-accent: var(--violet_400);
  --color-brand-accent-active: var(--violet_500);
  --color-brand-accent-hover: var(--violet_600);
  --color-brand-accent-selected: var(--violet_400);

  --color-brand-neutral: var(--coolGray80);

  --color-status-error: var(--red_500);

  //UI colors
  --color-ui-background-l: var(--white);
  --color-ui-container-l: var(--grey_100);

  --color-ui-field: var(--grey_100);
  --color-ui-field-focus: var(--white);

  --color-text-main: var(--grey_950);
  --color-text-main-lighter: var(--grey_900);
  --color-text-body: var(--black-90a);
  --color-text-muted: var(--black-60a);
  --color-text-disabled: var(--black-40a);

  --color-ui-border-lightest: var(--black-10a);
  --color-ui-border-subtle: var(--black-40a);
  --color-ui-border-heavy: var(--cool-grey-90a);
  --color-ui-border-solid: var(--grey_900);

  --breakpoint-mobile: 30em;
  --breakpoint-tablet: 48em;
  --breakpoint-desktop: 64em;

  --size-app-max-width: 64rem;
  --size-app-header-height: 4rem;
  --size-app-gutter-small: 1rem;
  --size-app-gutter-large: 2rem;

  --size-control-height-small: 2rem;
  --size-control-height-medium: 2.5rem;
  --size-control-height-large: 3rem;

  --border-width-thin: 1px;
  --border-width-mid: 2px;
  --border-width-thick: 4px;


}
