.Gallery {
  overflow: hidden;
  //aspect-ratio: 16/9;
  position: relative;
}
.Gallery-window {
  --index: 0;
  white-space: nowrap;
  transition: transform 250ms ease;
  height: 100%;
}
.Gallery-item {
  width: 100%;
  //display: inline-flex;
  //justify-content: center;
  display: inline-block;
  //align-items: center;
  //height: 20rem;
  height: 100%;
  //background-color: seagreen;
  position: relative;
  user-select: none;
}
.Gallery-item > img {
  object-fit: cover;
  max-width: 100%;
}

.Gallery-count {
  user-select: none;
}

.Gallery-item-trigger--left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
}
.Gallery-item-trigger--right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
}

.ProgressIndicator {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
.ProgressIndicatorItem {
  //clip-path: circle(50%);
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  //border: 2px solid #2f2f2f;
  background-color: #cccccc;
  transition: background-color 250ms ease;
}
.ProgressIndicatorItem.Active {
  background-color: var(--color-brand-primary);
}
