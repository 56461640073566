.ImageCard-container {
  display: inline-flex;
  flex-direction: column;
  width: 20rem;
  border: 1px solid var(--black-10a);
  border-radius: 0.5rem;
  overflow: hidden;
}
.ImageCard-image {
  display: flex;
  border-radius: 0.5rem;
  & > img {
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 4 / 3;
    border-radius: 0.5rem;
  }
}
.ImageCard-footer {

}
.MediaCard-container {
  display: inline-flex;
  flex-direction: column;
  width: 20rem;
  border: 1px solid var(--black-10a);
  border-radius: 0.5rem;
  overflow: hidden;
}
.MediaCard-media {
  display: flex;
  & > img {
    max-width: 100%;
    //height: auto;
    object-fit: cover;
    aspect-ratio: 16 / 10;
  }
}
.MediaCard-content {
  padding: 1rem;
  text-align: start;
}
.MediaCard-content.small {

}
.MediaCard-header {
  margin-bottom: 1rem;
}
.MediaCard-header.small {
  margin-bottom: 0.5rem;
  & > .Type-title {
    font-weight: 600;
  }
}


.Technology-card {
  display: inline-flex;
  //background-color: var(--color-ui-background-l);
  background-color: var(--color-brand-neutral);
  color: var(--white);
  border: 1px solid var(--color-ui-border-lightest);
  padding: 1rem;
  border-radius: 0.5rem;
  & > span {
    margin-right: 1rem;
  }
  $delay: 200ms;

  opacity: 0;
  //animation: Fade-in-contents 200ms ease forwards;

}
.animate > .Skills-group > .Technology-card {
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation: Fade-in-contents 100ms 100ms * $i ease forwards;
    }
  }
}

@mixin delayByN($elements) {
  @for $i from 1 through $elements {
    &:nth-child(#{$i}) {
      animation: Fade-in-contents 200ms 200ms * $i ease forwards;
    }
  }
}

@keyframes Fade-in-contents {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ProjectCard-grid {
  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));

  padding: 1rem;

  display: flex;
  gap: 1rem;

  @media screen and (max-width: 40em) {
    flex-direction: column;
  }
}
.ProjectPreviewCard {

}
.ProjectPreviewCard-container {
  background-color: var(--white);
  padding: 1rem;

  box-shadow:
          0px 0px 2px 0px rgba(24, 24, 24, 0.1),
          0px 0px 0px 1px rgba(24, 24, 24, 0.1);

  border-radius: 0.25rem;
}
.ProjectPreviewCard-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: 'Helvetica Neue', sans-serif;
}
.ProjectPreviewCard-body {
  line-height: 1.6;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 400;
}
.ProjectPreviewCard-actions {}