.group-flush > * {
  &:first-child, &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child, &:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
}
.Flex-space-x-1 {
  width: 1rem;
}
.relative {
  position: relative;
}
.fill-size {
  width: 100%;
  height: 100%;
}

.block {
  width: 100%;
}

.image-resizer-sw {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: -2px;
  left: -6px;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
  cursor: sw-resize;
}

.image-resizer-ne {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
  cursor: ne-resize;
}

.image-resizer-nw {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  top: -6px;
  left: -6px;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
  cursor: nw-resize;
}

.image-resizer-se {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: -2px;
  right: -6px;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
  cursor: nwse-resize;
}
