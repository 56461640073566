@use "sass:color";
@use "sass:math";
@use "sass:meta";
@use "sass:string";
@use "sass:selector";
@use "sass:map";
@use "sass:list";
@function convert($color) {

}
@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
@function useVar($v) {
  $newV: var(#{$v});
  @debug $newV;
  @return $newV;
}

@mixin createAlphaColorScale($color-name, $rgb) {
  @for $i from 1 to 10 {
    //--color-#{$color-name}-a#{$i}: rgba(#{$rgb}, #{$i/10});
    --color-#{$color-name}-a#{$i}: rgba(#{$rgb}, #{$i / 10});
  }
  //--color-#{$color-name}-alpha: 0;
}
:root {

  @include createAlphaColorScale("blue", (38, 128, 235));
  @include createAlphaColorScale("green", (45,157,120));

  --grey_000: #ffffff;
  --grey_100: #f5f5ff;
  --grey_200: #eaeaea;
  --grey_300: #d3d3d3;
  --grey_400: #bcbcbc;
  --grey_500: #959595;
  --grey_600: #747474;
  --grey_700: #505050;
  --grey_800: #323232;
  --grey_900: #212121;
  --grey_950: #131313;

  --ref-black: #000000;
  --black-10a: rgba(0, 0, 0, 0.1);
  --black-20a: rgba(0, 0, 0, 0.2);
  --black-30a: rgba(0, 0, 0, 0.3);
  --black-40a: rgba(0, 0, 0, 0.4);
  --black-50a: rgba(0, 0, 0, 0.5);
  --black-60a: rgba(0, 0, 0, 0.6);
  --black-70a: rgba(0, 0, 0, 0.7);
  --black-80a: rgba(0, 0, 0, 0.8);
  --black-90a: rgba(0, 0, 0, 0.9);
  --black-95a: rgba(0, 0, 0, 0.95);

  --ref-coolGray: 52, 58, 63,;
  $ref-cool-grey: 52, 58, 63;
  --cool-grey-10a: rgba(#{$ref-cool-grey}, 0.1);
  --cool-grey-20a: rgba(#{$ref-cool-grey}, 0.2);
  --cool-grey-30a: rgba(#{$ref-cool-grey}, 0.3);
  --cool-grey-40a: rgba(#{$ref-cool-grey}, 0.4);
  --cool-grey-50a: rgba(#{$ref-cool-grey}, 0.5);
  --cool-grey-60a: rgba(#{$ref-cool-grey}, 0.6);
  --cool-grey-70a: rgba(#{$ref-cool-grey}, 0.7);
  --cool-grey-80a: rgba(#{$ref-cool-grey}, 0.8);
  --cool-grey-90a: rgba(#{$ref-cool-grey}, 0.9);
  --cool-grey-95a: rgba(#{$ref-cool-grey}, 0.95);
  /*--ref-blue-var: var(--blue_600);
  $blue: string.unquote(var(--blue_600));
  ///$ref-blue: #0fb5f5;
  //$ref-blue: color.red(var(--blue_600)), color.green(var(--blue_600)), color.blue(var(--blue_600));
  $ref-blue-r: red($blue);
  $ref-blue-g: green($blue);
  $ref-blue-b: blue($blue);
  $ref-blue: rgb($ref-blue-r, $ref-blue-g, $ref-blue-b);
  --ref-blue: $ref-blue;
  --blue-10a: rgba(var(--ref-blue), 0.1);*/

  --coolGray10: #f2f4f8;
  --coolGray20: #dde1e6;
  --coolGray30: #c1c7cd;
  --coolGray40: #a2a9b0;
  --coolGray50: #878d96;
  --coolGray60: #697077;
  --coolGray70: #4d5358;
  --coolGray80: #343a3f;
  --coolGray90: #21272a;
  --coolGray100: #121619;



  --pure_black: #000;
  --white: #fff;
  --blue: #1473e6;
  --gray_50: #fff;
  --gray_75: #fff;
  --gray_100: #fff;
  --gray_200: #f4f4f4;
  --gray_300: #eaeaea;
  --gray_400: #d3d3d3;
  --gray_500: #bcbcbc;
  --gray_600: #959595;
  --gray_700: #747474;
  --gray_800: #505050;
  --gray_900: #323232;
  --blue_200: #5aa9fa;
  --blue_300: #4b9cf5;
  --blue_400: #378ef0;
  --blue_500: #2680eb;
  --blue_600: #1473e6;
  --blue_700: #0d66d0;
  --blue_800: #095aba;
  --red_400: #ec5b62;
  --red_500: #e34850;
  --red_600: #d7373f;
  --red_700: #c9252d;
  --orange_400: #f29423;
  --orange_500: #e68619;
  --orange_600: #da7b11;
  --orange_700: #cb6f10;
  --green_400: #33ab84;
  --green_500: #2d9d78;
  --green_600: #268e6c;
  --green_700: #12805c;
  --celery_200: #58e06f;
  --celery_300: #51d267;
  --celery_400: #4bc35f;
  --celery_500: #44b556;
  --celery_600: #3da74e;
  --celery_700: #379947;
  --chartreuse_300: #9bec54;
  --chartreuse_400: #8ede49;
  --chartreuse_500: #85d044;
  --chartreuse_600: #7cc33f;
  --chartreuse_700: #73b53a;
  --yellow_200: #ffe22e;
  --yellow_300: #fad900;
  --yellow_400: #edcc00;
  --yellow_500: #dfbf00;
  --yellow_600: #d2b200;
  --yellow_700: #c4a600;
  --magenta_200: #f56bb7;
  --magenta_300: #ec5aaa;
  --magenta_400: #e2499d;
  --magenta_500: #d83790;
  --magenta_600: #ca2982;
  --magenta_700: #bc1c74;
  --pink_400: #cf3edc;
  --pink_500: #c038cc;
  --pink_600: #b130bd;
  --pink_700: #a228ad;
  --purple_400: #9d64e1;
  --purple_500: #9256d9;
  --purple_600: #864ccc;
  --purple_700: #7a42bf;
  --purple_800: #6f38b1;
  --violet_200: #9090fa;
  --violet_300: #8282f6;
  --violet_400: #7575f1;
  --violet_500: #6767ec;
  --violet_600: #5c5ce0;
  --violet_700: #5151d3;
  --teal_200: #74c0d3;
  --teal_300: #23b2b8;
  --teal_400: #20a3a8;
  --teal_500: #1b959a;
  --teal_600: #16878c;
  --teal_700: #0f797d;
}
