@use "src/styles/variables";


.Modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: var(--black-60a);

  display: flex;
  justify-content: center;
  align-items: center;
}

.View-more-cta {
  font-family: GilroyBold, sans-serif;
  font-size: 1rem;
  padding: 1rem;
}


body {
  animation: Splash-scroll-lock 4000ms linear forwards;
}
@keyframes Splash-scroll-lock {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: initial;
  }
}
.App {
  @media screen and (max-width: variables.$breakpoint){
    padding: 0 1rem;
  }
}

.Button-group.intro {
  display: flex;
  justify-content: end;
  gap: 4rem;
  /* margin-left: 16rem; */
  max-width: 80ch;
  margin: 0 auto;
  @media screen and (max-width: 40em){
    justify-content: center;
  }
}
.About-paragraph {

  /*@media screen and (max-width: 40em){
    width: max-content;
  }*/

  /*display: flex;
  place-content: center;
  flex-direction: column;
  align-items: center;
  width: max-content;
  max-width: 80ch;*/
  display: grid;
  place-content: center;
  margin: 0 auto;
  & > .Type-title {
    font-weight: 700;
    align-self: flex-start;
  }
  & > .Type-body {
    max-width: 60ch;
    font-weight: 400;
    @media screen and (max-width: 40em){
      font-size: 1rem;
    }
  }

}

.Get-in-touch-container {
  padding: 1.5rem 2rem;
  background-color: var(--white);
  //background-color: rgba(38, 128, 235, 0.1);
  //border: 4px dashed rgba(38, 128, 235, 0.4);
  //border: 2px solid rgba(38, 128, 235, 0.4);
  //outline: 8px solid rgba(38, 128, 235, 0.6);
  border-radius: 1rem;
  max-width: 40rem;
  width: max-content;
  ////margin: 0 auto;

  margin: 0 auto;
  text-align: center;

  display: flex;
  flex-direction: column;
/*  transform-origin: center;
  opacity: 0;
  animation: GetInTouch-in 500ms 5000ms ease forwards;*/

  /*margin-left: auto;
  margin-right: 1rem;
  @media screen and (max-width: 640px){
    margin: 0 auto;
  }*/
}
.Get-in-touch-container > .title {
  font-weight: 700;
  color: var(--color-brand-primary);
  border-bottom: 1px solid var(--black-20a);
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.Social-icons {
  /*display: flex;
  max-width: 8rem;
  justify-content: space-between;*/
  text-align: center;
  margin-top: 1rem;
  & > :first-child {
    margin-right: 2rem;
  }
}
.Group-email-and-phone {
  margin-top: 1rem;
  border-top: 2px solid var(--black-20a);
  & > * {
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1rem;
  }
  & > :first-child {
    margin-bottom: 1rem;
  }
}
@keyframes GetInTouch-in {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}
.Profile-card {
  border: 1px solid var(--black-10a);
  border-bottom: 8px solid var(--color-brand-primary);
  box-shadow: var(--black-10a) 1px 2px 5px;
  border-radius: 1rem 1rem 0rem 8rem;
  max-width: 60rem;
  padding: 1rem 2rem;
  //padding: 1rem;
  background-color: var(--white);

  @media screen and (max-width: 400px){
    display: flex;
  }
  @media screen and (max-width: 640px){
    display: flex;
    flex-direction: column;
    border-radius: 1rem 1rem 0rem 2rem;
  }
  @media screen and (max-width: 960px){

  }

}
.Profile-card > .Heading-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;

  @media screen and (max-width: 640px){
    display: flex;
    flex-direction: row;//c
    align-items: start;
  }
}
.Profile-card-text-container {
  @media screen and (max-width: 640px){
    display: flex;
    flex-direction: column;
  }
}
.Profile-card-text-container > .title {
  font-size: 6rem;
  letter-spacing: -0.025em;
  line-height: 1;
  @media screen and (max-width: 768px){
    font-size: 5rem
  }
  @media screen and (max-width: 640px){
    /*font-size: 2.5rem;
    position: absolute;
    top: -2.5rem;
    left: 0;*/
    font-size: 1.75rem;
    //margin-bottom: 1rem;
  }
  @media screen and (max-width: 320px){
    font-size: 1.5rem;
  }
}
.Profile-card-text-container > .subtitle {
  //margin-left: 0.5rem;
  font-size: 2rem;
  letter-spacing: -0.025em;
  line-height: 1;
  @media screen and (max-width: 640px){
    font-size: 1rem;
    margin-top: 1rem;
  }
  @media screen and (max-width: 320px){
    margin-top: 0.5rem;
  }
}

.Section-featured-projects {
  max-width: 60rem;
  margin: 0 auto;
  & > * {
    margin-bottom: 2rem;
  }
}

.Splash-intro-container {
  width: 100%;
  height: 100vh;
  background-color: var(--blue_200);
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 8000;
  animation: Z-top-to-bottom-layer 200ms 1500ms ease forwards;
}
@keyframes Z-top-to-bottom-layer {
  0% {
    z-index: 8000;
    display: flex;
  }
  100% {
    z-index: -1;
    display: none;
  }
}
.Section-intro {
  //margin-top: 5rem;
  margin-top: 8rem;
  opacity: 0;
  animation: Splash-opacity-in 500ms 1700ms ease forwards;
}
@keyframes Splash-opacity-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Splash-circle-mask {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  --r: 0;
  clip-path: circle(var(--r) at 50% 50%);
  animation: Circle-mask-in 500ms 1000ms cubic-bezier(0.5,0,1,0.5) forwards;
  //z-index: 800;
}
.Intro-circle-mask {
  //width: 8rem;
  //height: 8rem;
  --r: 0;
  clip-path: circle(var(--r) at 50% 50%);
  //animation: Circle-mask-in-v 500ms 500ms ease forwards;
  animation: Circle-mask-in 500ms 1500ms ease forwards;
}
.Section-work-heading {
  max-width: 60rem;
  margin: 0 auto 1rem auto;
}
.Featured-skills-card {
  border: 2px solid var(--color-ui-border-lightest);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: var(--black-10a) 2px 4px 6px;
  margin-bottom: 4rem;
  background-color: var(--white);
}
.Section-skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  max-width: 60rem;
  margin: 0 auto;
}
.Skills-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  //grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  //background-color: var(--cool-grey-10a);
  //border: 2px solid var(--color-brand-neutral);
  padding: 1rem;
  //border-radius: 1rem;
}
.Skills-g2 {
  max-width: 60rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 640px){
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
}
.Skills-g2-subgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media screen and (max-width: 640px){
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
}
.Intro-block {
  background-color: var(--white);
  //opacity: 0;
  transform-origin: bottom center;
  //transform: scaleX(0);

  animation: Intro-block-in 200ms 1000ms ease forwards;
}
.Intro-block::before {
  content: '';
  width: 100%;
  height: 1rem;
  background-color: var(--color-brand-neutral);
  top: calc(100% - 1rem);
  position: absolute;
  left: 0;
  transform-origin: left;
  transform: scaleX(0);
  animation: Underline-in 200ms 500ms cubic-bezier(0.5, 0, 1, 0.5) forwards;//,
//Underline-in-up 200ms 800ms cubic-bezier(0.5, 0, 1, 0.5) forwards;
}

@keyframes Underline-in {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes Underline-in-up {
  0% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes Intro-block-in {
  0% {
    background-color: var(--white);
    color: var(--color-brand-neutral);
    //opacity: 0;
    //transform: scaleY(0);
  }
  50% {
    //transform: scaleX(1);
    //color: var(--cool-grey-40a);
    //background-color: var(--color-brand-neutral);
  }
  100% {
    background-color: var(--color-brand-neutral);
    color: var(--white);
    //opacity: 1;
    //transform: scaleY(1);
  }
}
@keyframes Circle-mask-in {
  0%{
    clip-path: circle(0% at 50% 50%);
  }
  100% {
    clip-path: circle(80% at 50% 50%);
  }
}
@keyframes Circle-mask-in-v {
  0%{
    --r: 0%;
  }
  100% {
    --r: 80%;
  }
}
