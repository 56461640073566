
@font-face {
  font-family: GilroyBold;
  src: local(GilroyBold), url("./fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: GilroyBlack;
  src: local(GilroyBlack), url("./fonts/Gilroy/Gilroy-Black.ttf") format("truetype");
  font-weight: auto;
}
@font-face {
  font-family: GilroyHeavy;
  src: local(GilroyHeavy), url("./fonts/Gilroy/Gilroy-Heavy.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: GilroySemiBold;
  src: local(GilroySemiBold), url("./fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: GilroyLight;
  src: local(GilroyLight), url("./fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: HelveticaNeueBold;
  src: local(HelveticaNeueBold), url("./fonts/HelveticaNeue/HelveticaNeueBd.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: HelveticaNeueHeavy;
  src: local(HelveticaNeueHeavy), url("./fonts/HelveticaNeue/HelveticaNeueHv.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: HelveticaNeueMedium;
  src: local(HelveticaNeueMedium), url("./fonts/HelveticaNeue/HelveticaNeueMed.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: HelveticaNeueLight;
  src: local(HelveticaNeueLight), url("./fonts/HelveticaNeue/HelveticaNeueLt.ttf") format("truetype");
  font-weight: bold;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Helvetica Now Text','Neue Haas Grotesk Display Pro', 'Frutiger','Univers','Avenir','Avenir Next','Avenir Next Condensed', 'Barlow', 'Bitter', 'DM Sans', 'Fugaz One','Gilroy','Heebo','IBM Plex Sans','Kanit','Inter','Libre Franklin','Merriweather','Mulish','Noto Sans','Open Sans','Poppins','Raleway','Rancho','Rubik','Work Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
